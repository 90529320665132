import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '/src/components/LocalizedLink';
import Layout from '../../../components/Layout';

import IconArrowRight from '../../../assets/images/svg/arrow-right.inline.svg';

import iconTick from '../../../assets/images/svg/tick.svg';
import imgBlockR from '../../../assets/images/entry-requirements/Photo 1 (6).png';

import imgDots from '../../../assets/images/home-page/Dots.png';

import '../../../assets/styles/pages/entry-requirements-page.scss';

const EntryRequirements = () => {
  return (
    <Layout title={{ id: 'entryReq.seo.title' }} description={{ id: 'entryReq.seo.description' }}>
      <div className="entry-requirements-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">
              <FormattedMessage id="entryReq.sec1.title" />
            </h1>
          </div>
        </section>

        <section className="second-section">
          <div className="container">
            <div className="row top">
              <div className="col-12 col-lg-6 col-md-7">
                <div className="block_l">
                  <h2 className="c-title-34 block__title">
                    <FormattedMessage id="entryReq.sec2.benefitsTitle" />
                  </h2>
                  <div className="c-benefit-list">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="entryReq.sec2.ben1" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="entryReq.sec2.ben2" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="entryReq.sec2.ben3" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="entryReq.sec2.ben4" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="entryReq.sec2.ben5" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="entryReq.sec2.ben6" />
                      </p>
                    </div>
                  </div>
                  <p className="c-text-16 block__description">
                    <FormattedMessage id="entryReq.sec2.text" />
                  </p>
                  <FormattedMessage id="url.apply.index">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red">
                        <FormattedMessage id="menu.signUpNow" />
                        <IconArrowRight />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-5">
                <div className="block_r">
                  <div className="block_r__img">
                    <img src={imgBlockR} alt="" className="block_r__img" />
                  </div>

                  <div className="block__logo block_r__trinity">
                    <img src={imgDots} alt="iconTrinity" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default EntryRequirements;
